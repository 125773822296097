@import '@pushpress/shared-components/build/styles/config.scss';

.root {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  background: #ffffff;
  border: 1px solid #dae0e6;
  border-radius: 5px;
  text-align: left;
  outline: none;
  cursor: pointer;
  &:hover {
    border: 1px solid var(--pacific-primary-600);
  }
  &:focus {
    border: 1px solid var(--pacific-primary-600);
    box-shadow: 0px 0px 0px 3px #e6f4ff;
  }
  &:active {
    border: 1px solid var(--pacific-primary-600);
    box-shadow: 0px 0px 0px 3px #e6f4ff;
    background: $wolf-100;
  }

  &.booked {
    background: $wolf-100;
    pointer-events: none;
  }
}

.body {
  flex: 1;
}

.title {
  font-weight: 700;
}

.bookedTag {
  text-transform: uppercase;
  background: $sea-foam-200;
  color: $sea-foam-700;
  padding: 3px 6px;
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  border-radius: 4px;
}
