@import '@pushpress/shared-components/build/styles/config.scss';

.text {
  @include body-lg;
  color: $white;
}

.amount {
  @include heading-1;
  color: $white;
  margin-bottom: 40px;
}

.appointment-name {
  @include heading-4;
  color: $white;
  margin-top: 40px;
  margin-bottom: $grid-unit-30;
}

.transaction-breakdown {
  margin-bottom: $grid-unit-40;
}

.breakdown-line-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
