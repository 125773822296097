@import '@pushpress/shared-components/build/styles/config.scss';

.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #fff;

  &.blur {
    filter: blur(12px);
  }

  .header {
    flex: 0 0 64px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 3;
    .header-content {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      height: 64px;
      padding-right: 20px;
      background: #fff;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15), inset 0px -1px 0px #dae0e6;
    }
  }
  .body {
    flex: 1;
    overflow: auto;
    .body-content {
      position: relative;
      width: 751px;
      margin: auto;
      padding: 30px 0;
      @media (max-width: 480px) {
        width: 100%;
        padding: 30px 25px;
      }
    }

    .documents {
      padding: 0;
      width: 100%;
      height: 100%;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 144px;
    .footer-content {
      margin: auto;
      height: 144px;
      background: $pacific-primary-600;
      @media (max-width: 480px) {
        width: 100%;
      }
    }
  }
}
