@import '@pushpress/shared-components/build/styles/config.scss';

.container {
  display: flex;
  flex-direction: column;
  background-color: $white;
  min-height: 430px;
  border-radius: 10px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.15);

  @media (max-width: $breakpoint-mobile) {
    width: calc(100vw - $grid-unit-40);
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: $grid-unit-40 $grid-unit-30 $grid-unit-50;
}
