@import '@pushpress/shared-components/build/styles/config.scss';

.container {
  box-sizing: border-box;
  max-width: 800px;
  min-height: 100vh;
  margin: auto;
  padding: 24px;
  background: #fff;
}
