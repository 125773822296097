@import '@pushpress/shared-components/build/styles/config.scss';

.timezone-row {
  display: flex;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 36px;
}

.icon-globe {
  color: $wolf-600;
  margin-right: $grid-unit-30;
}

.timezone-description {
  @include body-md;
  color: $wolf-600;
}

.available-slots {
  display: flex;
  flex-direction: column;
  gap: $grid-unit-20;
  align-items: center;
  justify-content: center;
}
