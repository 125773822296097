@import '@pushpress/shared-components/build/styles/config.scss';

.modalContent {
  width: 360px;
  background: white;
  border-radius: 10px;
}

.drawer {
  :global(.ScModal-content) {
    background: transparent !important;
  }
}

.drawerContent {
  background: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.header {
  display: flex;
  align-items: center;
  padding: 24px 24px 0 24px;
}

.title {
  flex: 1;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #19c85d;
  border-radius: 999px;
  width: 24px;
  height: 24px;
  > svg {
    width: 16px;
    height: 16px;
    color: #fff;
  }
}

.body {
  padding: 12px 24px 24px 24px;
  &:empty {
    padding: 0 24px 24px 24px;
  }
}

.footer {
  border-top: 1px solid $wolf-300;
  padding: 24px;
}
