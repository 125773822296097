@import '@pushpress/shared-components/build/styles/config.scss';

.confirmation-message {
  font-family: $base-font-family !important;

  /* Default styles for p */
  p {
    margin-bottom: 1em;
  }

  /* Default styles for unordered lists (UL) */
  ul {
    list-style-type: disc; /* Default bullet style: filled circle */
    margin-left: 1.25em; /* Default left margin for the list */
    padding-left: 1em; /* Default left padding for the list */
  }

  /* Default styles for ordered lists (OL) */
  ol {
    list-style-type: decimal; /* Default numbering style: decimal (1, 2, 3, ...) */
    margin-left: 1.25em; /* Default left margin for the list */
    padding-left: 1em; /* Default left padding for the list */
  }
}
