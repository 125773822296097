@import '@pushpress/shared-components/build/styles/config.scss';

.root {
  font-family: Lato;
  line-height: 1.5;
  color: #3f4b57;
  font-size: 14px;
  h1 {
    margin: 20px 0 16px;
    font-size: 32px;
    font-weight: bold;
  }
  h2 {
    margin: 16px 0 12px;
    font-size: 28px;
    font-weight: bold;
  }
  h3 {
    margin: 16px 0 12px;
    font-size: 24px;
    font-weight: bold;
  }
  h4 {
    margin: 16px 0 12px;
    font-size: 20px;
    font-weight: bold;
  }
  h5 {
    margin: 16px 0 12px;
    font-size: 16px;
    font-weight: bold;
  }
  h6 {
    margin: 16px 0 12px;
    font-size: 14px;
    font-weight: bold;
  }
  p {
    margin: 1rem 0;
  }
  strong {
    font-weight: bold;
  }
  ul {
    list-style: disc;
    margin: 1rem 0;
    padding-left: 40px;
  }
  ol {
    list-style: decimal;
    margin: 1rem 0;
    padding-left: 40px;
  }
  table {
    width: 100%;
    border-top: 1px solid $wolf-400;
    border-left: 1px solid $wolf-400;
    td {
      padding: 8px 16px;
      border-bottom: 1px solid $wolf-400;
      border-right: 1px solid $wolf-400;
    }
  }
}
