@import '@pushpress/shared-components/build/styles/config.scss';

.root {
  width: 400px;
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.15);
  @media (max-width: $breakpoint-mobile) {
    width: calc(100vw - $grid-unit-40);
  }
  .header {
    position: relative;
  }
  .content {
    position: relative;
    padding: 20px;
  }
}
