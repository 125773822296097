@import '@pushpress/shared-components/build/styles/config.scss';

.root {
  width: 100%;
  height: 100%;
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
