@import '@pushpress/shared-components/build/styles/config.scss';

.root {
  display: flex;
  padding: 24px;
  background: $white;
  border-radius: 10px;
  .icon {
    margin-right: 24px;
  }
  .content {
    flex: 1;
  }
}
