@import '@pushpress/shared-components/build/styles/config.scss';

.modalContent {
  width: 360px;
  background: white;
  border-radius: 10px;
}

.drawer {
  :global(.ScModal-content) {
    background: transparent !important;
  }
}

.drawerContent {
  background: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.content {
  padding: 24px;
}

.action {
  padding: 24px;
}

.bulletSeparator {
  padding: 0 8px;
}
