@import '@pushpress/shared-components/build/styles/config.scss';

.container {
  width: 400px;
  background-color: $white;
  min-height: 430px;
  border-radius: 10px;
  box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.15);

  @media (max-width: $breakpoint-mobile) {
    width: calc(100vw - $grid-unit-40);
  }
}

.form {
  width: 100%;
}

.header {
  padding: 32px 24px 0 24px;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px 24px 8px 24px;
}

.footer {
  padding: 24px;
  border-top: 1px solid #dae0e6;
}
